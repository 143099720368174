import { ConsolidatedHistory } from '@obeta/models/lib/models/History'

/**
 * Extract query params as object from url string.
 * @param search History location search
 * @returns URL search params object
 */
export const getURLSearchParamsByLocationSearch = <T>(search: string): T => {
  const urlSearchParams = new URLSearchParams(search)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.fromEntries(urlSearchParams.entries())
}
export const getURLSearchParamsValue = <T, U>(
  defaultValues: T,
  history: ConsolidatedHistory<unknown>,
  key: string
): string | undefined => {
  let params: undefined | U = undefined
  if (history?.location?.search) {
    params = getURLSearchParamsByLocationSearch<U>(history.location.search)
    return params[key] ?? defaultValues[key]
  }
  return defaultValues[key]
}

/**
 * Remove specific key from history location search.
 * @param key key
 * @param search History location search
 */
export const removeKeyFromHistoryLocationSearch = (
  key: string,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  search: string
): string => {
  const urlSearchParams: { [key: string]: any } = getURLSearchParamsByLocationSearch(search)

  // Filter out specific key
  const filteredQueryParams = Object.fromEntries(
    Object.entries(urlSearchParams).filter(([k]) => {
      return k !== key
    })
  )

  return new URLSearchParams(
    filteredQueryParams as
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams
      | undefined
  ).toString()
}

export const splitString = (key?: string): string[] => {
  return key?.split(',') ?? []
}

/**
 * Update url search params by filtered values.
 * @param urlSearchParams URLSearchParams
 * @param history History
 * @param pathname Pathname
 */
export const updateURLSearchParams = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  urlSearchParams: { [key: string]: any },
  history: ConsolidatedHistory<unknown>,
  pathname: string,
  removeKey?: string
) => {
  // Filter out empty values
  let filteredQueryParams = Object.fromEntries(
    Object.entries(urlSearchParams).filter(([key, value]) => {
      return value !== null && value !== undefined && value !== '' && value.length !== 0
    })
  )

  // Filter out specific key
  if (removeKey) {
    filteredQueryParams = Object.fromEntries(
      Object.entries(filteredQueryParams).filter(([key]) => {
        return key !== removeKey
      })
    )
  }

  // Replace url search params with current values
  history.replace({
    pathname,
    search: new URLSearchParams(
      filteredQueryParams as
        | string
        | string[][]
        | Record<string, string>
        | URLSearchParams
        | undefined
    ).toString(),
  })
}
